<template>
  
    <v-container>
      
        <v-row justify="center">

        
            <v-col cols="12" md="8" lg="8">

                <v-card outlined>
             
                    <v-row no-gutters class="pa-0 ma-0">
                        <v-col cols="9" md="10">
                            <v-card-title v-text="notice.title.substring(0,22)"></v-card-title>
                        </v-col>
                        <v-col cols="3" md="2" class="
                            d-flex
                            justify-center
                            align-center
                        ">
                        <v-dialog
        v-model="dialog"
        width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            
            v-bind="attrs"
            v-on="on"
          >
            View
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <v-row
        
        >
          <v-col cols="12" md="8" lg="9">  <span class="text-h5">Notice Details</span>
          </v-col>
          <v-col cols="12" md="4" lg="3" pl="2">
            <!-- <v-btn
          class="ma-2 ml-16"
         
          @click="dialog = false"
        >
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
          
        </v-btn>   -->
        <v-btn icon class="ma-2 ml-16"
         
         @click="dialog = false">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
         
          </v-col>
        </v-row>
           
          </v-card-title>
          
          <v-card-text>
            <v-card>
      <v-toolbar
        color="primary"
        dark
        dense
        flat
      >
        <v-toolbar-title class="text-body-2">
          {{notice.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        {{ notice.content }}
           </v-card-text>
  
      <v-card-actions>
        <v-spacer></v-spacer>
        
      </v-card-actions>
    </v-card>         
  </v-card-text>
          
        </v-card>
      </v-dialog>
                            <!-- <v-chip v-if="notice.type" v-text="notice.type"></v-chip> -->
                        </v-col>
                    </v-row>
                    <v-card-subtitle class="my-1"
                        v-text="notice.created_at ? new Date(notice.created_at).toLocaleString() : ''"></v-card-subtitle>
                    <v-divider class="mx-2"></v-divider>
                    <!-- <v-card-text v-text="notice.content"></v-card-text> -->
                    <!-- <v-dialog v-model="noticeEdit" width="800">

                        
            <template v-slot:activator="{ on, attrs }">
              <v-list-item text v-bind="attrs" v-on="on" link
                >     <v-icon class="px-2" color="accent">mdi-note-edit</v-icon
                >Update 

                
              </v-list-item>
            </template>

           
          </v-dialog> -->
          <!-- <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="blue lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Edit
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
           Notice
          </v-card-title>
  
          <v-card-text>
            <v-text-field  v-model="notice.title" label="Title*"></v-text-field>
                
               
                <v-textarea  rows="5" v-model="notice.content" label="Content*"></v-textarea>
                
   
     <v-btn color="secondary" type="submit"  @click="edit()"  >Save </v-btn>
              </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              exit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
                     -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import EditNotice from "../../pages/notice/EditNotice.vue";
export default {
    // components: {EditNotice },
    data: () => ({
      dialog:false,
        notice: {
            title: '',
            content: '',
            created_at: '',
            type: '',
            // notice: {},

            // noticeEdit:false
        }
    }),
    
    async created() {
        await this.loadNotice()
    },
    methods: {
        loadNotice() {
            return this.$api.get(`/notice/${this.$route.params.id}`)
                .then(response => {
                    this.notice = response.data
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
        edit( ) {
      return this.$api
        .put(`/notice/${this.$route.params.id}/`,this.notice).then((res)=>this.notice=res.data)
        .then((r) => console.log(r));
    },

//         edit(id) {
    
//     const data = {

//         title: this.title,
//         content: this.content,
     
      
          
        
//       };
     
    
//     this.$api.put('/notice/', { title: this.title, content: this.content })
//       .catch((err) => {
//         console.log(err);
//         // alert("Please enter all inputs!");
//       });
//       // alert("data saved");

//     this.title = null;
//     this.content = null;
    
//   },

    
    
    }
}
</script>

<style>

</style>